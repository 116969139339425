// extracted by mini-css-extract-plugin
export var active = "Search-module--active--RjfQp";
export var container = "Search-module--container--L1o+c";
export var container__animatedMask = "Search-module--container__animatedMask--rHsVk";
export var container__button = "Search-module--container__button--DdjUs";
export var container__button__icon = "Search-module--container__button__icon--nvWSG";
export var container__form = "Search-module--container__form--sgqZG";
export var container__form__input = "Search-module--container__form__input--OMneS";
export var hidden = "Search-module--hidden--GYugj";
export var maskAnimation = "Search-module--maskAnimation--FEsH1";
export var widthAnimation = "Search-module--widthAnimation--eajRZ";