import 'assets/blog.scss'

import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo'
import Blog from 'components/Blog'

import blogSettings from 'site/blog/blog_settings.json'

const BlogIndex = ({
  data: {
    allMarkdownRemark: { nodes: allPosts },
  },
  location,
}) => {
  return (
    <Layout
      location={location}
      title={blogSettings.title}
      subTitle={blogSettings.sub_title}
      className="blog">
      <SEO title={blogSettings.title} location={location.href} />
      <Blog allPosts={allPosts} />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(blog)/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          author
          categories
          image {
            publicURL
          }
        }
      }
    }
  }
`
