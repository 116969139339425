import React from 'react'

import * as styles from './Item.module.scss'

import cn from 'classnames'

const Item = ({ number, onSetPage, isActive, className }) => {
  const handleSetPage = () => onSetPage(number)

  return (
    <button
      type="button"
      onClick={handleSetPage}
      className={cn(styles.item, { [styles.active]: isActive }, className)}>
      {number}
    </button>
  )
}

export default Item
