import React, { useState } from 'react'

import * as styles from './Search.module.scss'

import cn from 'classnames'

import SearchIcon from 'components/Icons/Search'

import { Input } from 'ui'

import blogSettings from 'site/blog/blog_settings.json'

const searchPlaceholder = blogSettings.blog_search_placeholder

const defaultValidator = {
  keyword: { value: false, message: '' },
}

const Search = ({ onOpen, onClose, onSearch }) => {
  const [isOpen, setIsOpen] = useState(false)

  const [validatorState, setValidator] = useState(defaultValidator)
  const [formState, setformState] = useState({ keyword: '' })

  const handleOpen = () => {
    setIsOpen(true)
    onOpen()
  }
  const handleClose = () => {
    setIsOpen(false)
    onClose()
  }

  const handleChangeForm = () => {
    setValidator(defaultValidator)
  }

  const setFormValue = (name, value) => {
    setformState(prev => ({ ...prev, [name]: value }))
  }

  const clear = name => {
    setValidator(c => ({ ...c, [name]: { value: '', message: '' } }))
  }

  const validate = () => {
    if (formState.keyword.length > 200) {
      setValidator(c => ({
        ...c,
        keyword: { value: true, message: 'Keyword is too long' },
      }))
      return false
    } else {
      clear('keyword')
    }

    return true
  }

  const handleSubmit = e => {
    e.preventDefault()

    const isValid = validate()
    if (isValid) {
      const formData = new FormData(e.target)
      const keyword = formData.get('keyword')

      onSearch(keyword)
    }
  }

  return (
    <div className={cn(styles.container, { [styles.active]: isOpen })}>
      <button
        type="button"
        className={cn(styles.container__button, { [styles.hidden]: isOpen })}
        onClick={handleOpen}>
        <SearchIcon className={styles.container__button__icon} />
      </button>
      {isOpen && (
        <>
          <form
            className={styles.container__form}
            onSubmit={handleSubmit}
            onChange={handleChangeForm}
            noValidate>
            <button
              type="submit"
              className={cn(styles.container__button, styles.active)}>
              <SearchIcon className={styles.container__button__icon} />
            </button>
            <Input
              error={validatorState.keyword?.message}
              type="text"
              name="keyword"
              required
              placeholder={searchPlaceholder}
              setFormValue={setFormValue}
              autoFocus
              className={styles.container__form__input}
              afterClearValue={handleClose}
              alwaysInFocus={true}
            />
          </form>
          <div className={styles.container__animatedMask}></div>
        </>
      )}
    </div>
  )
}

export default Search
