import React, { useState, useEffect, useCallback } from 'react'

import * as styles from './Blog.module.scss'
import cn from 'classnames'

import category from 'site/blog/category.json'

import Pagination from './Pagination'

import PostListContainer from './PostListContainer'
import PostItem from './Postitem'

import Header from './Header'

import { useWindowSize } from 'utils'

import blogSettings from 'site/blog/blog_settings.json'

const defaultCategory = blogSettings.blog_default_category

const initialPage = 1
const initialOffset = 0

const itemsPerPageOnWeb = blogSettings.blog_items_per_page_web
const itemsPerPageOnMob = blogSettings.blog_items_per_page_mob

const breakPoint = 992

const filterPostsWithCategory = (posts, category) =>
  posts.filter(post => post.frontmatter.categories === category)

const filterPostsWithKeyword = (posts, keyword) =>
  posts.filter(({ frontmatter: { title } }) =>
    title.toLowerCase().includes(keyword.toLowerCase()),
  )

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

const Blog = ({ allPosts }) => {
  const [posts, setPosts] = useState(allPosts)
  const [visiblePosts, setVisiblePosts] = useState([])

  const [selectedCategory, setSelectedCategory] = useState(defaultCategory)

  const [page, setPage] = useState(initialPage)
  const [offset, setOffset] = useState(initialOffset)
  const [pagesCount, setPagesCount] = useState(0)

  const [windowWidth] = useWindowSize()

  const onChangeFilterTag = useCallback(
    category => {
      setSelectedCategory(category)
      setPage(initialPage)
      setOffset(initialOffset)
      if (category === defaultCategory) {
        setPosts(allPosts)
      } else {
        setPosts(() => filterPostsWithCategory(allPosts, category))
      }
    },
    [allPosts],
  )

  const onIncrementPage = useCallback(() => {
    setPage(prev => prev + 1)
    scrollToTop()
  }, [])

  const onDecrementPage = useCallback(() => {
    setPage(prev => prev - 1)
    scrollToTop()
  }, [])

  const onSetPage = useCallback(number => {
    setPage(number)
    scrollToTop()
  }, [])

  const onSearch = useCallback(keyword => {
    setPage(initialPage)
    setOffset(initialOffset)
    setSelectedCategory(defaultCategory)

    setPosts(() => filterPostsWithKeyword(allPosts, keyword))
  }, [])

  const onResetSearch = useCallback(() => {
    setSelectedCategory(defaultCategory)
    setPosts(allPosts)
  }, [])

  useEffect(() => {
    const offset =
      windowWidth > breakPoint ? itemsPerPageOnWeb : itemsPerPageOnMob

    setOffset(offset)

    setPagesCount(Math.ceil(posts.length / offset))
  }, [posts, page, windowWidth])

  useEffect(() => {
    setVisiblePosts(posts.slice(offset * page - offset, offset * page))
  }, [posts, page, offset])

  return (
    <div className={cn('container', styles.spacing)}>
      <Header
        filterProps={{
          isMobile: windowWidth <= 1441,
          filterTagsArray: category.category,
          defaultTag: defaultCategory,
          filterTag: selectedCategory,
          onChangeFilterTag: onChangeFilterTag,
        }}
        searchProps={{ onSearch }}
        onResetSearch={onResetSearch}
      />
      <PostListContainer>
        {visiblePosts?.map(
          (
            { frontmatter: { title, categories, image }, fields: { slug } },
            index,
          ) => (
            <PostItem
              // isLarge={index <= 1 && page === initialPage}
              title={title || slug}
              categories={categories}
              // author={author}
              image={image}
              slug={slug}
              key={slug}
            />
          ),
        )}
      </PostListContainer>
      <Pagination
        currentPage={page}
        pagesCount={pagesCount}
        onIncrementPage={onIncrementPage}
        onDecrementPage={onDecrementPage}
        onSetPage={onSetPage}
      />
    </div>
  )
}

export default Blog
