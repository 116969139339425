import React, { useState } from 'react'

import * as styles from './Header.module.scss'

import Search from './Search'
import Filters from 'components/Filters'

const Header = ({ filterProps, searchProps, onResetSearch }) => {
  const [isFiltersHidden, setIsFiltersHidden] = useState(false)

  const handleHideFilters = () => setIsFiltersHidden(true)
  const handleShowFilters = () => {
    onResetSearch()
    setIsFiltersHidden(false)
  }

  return (
    <div className={styles.container}>
      {!isFiltersHidden && (
        <Filters {...filterProps} itemClassName={styles.animation} />
      )}
      <Search
        {...searchProps}
        onOpen={handleHideFilters}
        onClose={handleShowFilters}
      />
    </div>
  )
}

export default Header
