import React from 'react'

import cn from 'classnames'

import * as styles from './Postitem.module.scss'

import { Link } from 'gatsby'

// const capitalizeFirstLetterOfEachWord = str => {
//   return str.toLowerCase().replace(/(?:^|\s)\S/g, char => char.toUpperCase())
// }

const Postitem = ({ title, categories, image, slug, isLarge }) => {
  // const categoryFormatted = capitalizeFirstLetterOfEachWord(categories)

  return (
    <li
      className={cn(styles.item, { [styles.large]: isLarge })}
      itemScope
      itemType="http://schema.org/Article"
      key={`post-${slug}`}>
      <Link to={`/blog${slug}`} itemProp="url">
        <div className={styles.item__cover}>
          <img src={image?.publicURL} alt="post-image" />
        </div>

        <h3 className={styles.item__category} itemProp="headline">
          {categories}
        </h3>

        <h2 className={styles.item__title} itemProp="headline">
          {title}
        </h2>
      </Link>
    </li>
  )
}

export default Postitem
