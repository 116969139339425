import React, { useMemo } from 'react'

import * as styles from './Pagination.module.scss'

import ChevronLeft from 'components/Icons/ChevronLeft'
import ChevronRight from 'components/Icons/ChevronRight'

import Item from './Item'

const Pagination = ({
  currentPage,
  pagesCount,
  onSetPage,
  onIncrementPage,
  onDecrementPage,
}) => {
  const paginationItems = useMemo(() => {
    const items = []

    for (let i = 1; i <= pagesCount; i++) {
      items.push(i)
    }

    return items
  }, [pagesCount, currentPage])

  return (
    <div className={styles.container}>
      <button
        type="button"
        onClick={onDecrementPage}
        className={styles.container__button}
        title="Prev"
        disabled={currentPage === 1}>
        <ChevronLeft className={styles.container__button__icon} />
      </button>
      <div className={styles.container__items}>
        {paginationItems.map((number, i) => (
          <Item
            number={number}
            onSetPage={onSetPage}
            isActive={number === currentPage}
            key={`pagination-item-${i}`}
          />
        ))}
      </div>

      <button
        type="button"
        onClick={onIncrementPage}
        className={styles.container__button}
        title="Next"
        disabled={currentPage === pagesCount}>
        <ChevronRight className={styles.container__button__icon} />
      </button>
    </div>
  )
}

export default Pagination
